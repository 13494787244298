<template>
  <b-card>
    <b-card-title class="text-left">80/20 Zones</b-card-title>
    <b-card-sub-title class="text-left">
      <label for="threshold-pace">Threshold Pace:</label>
      <b-form-timepicker
          id="threshold-pace"
          v-model="selectorValue"
          :hour12="false"
          label-hours="Minutes"
          label-minutes="Seconds"
          @input="onPaceUpdate"
      ></b-form-timepicker>
    </b-card-sub-title>
    <b-card-body>
      <b-table striped small :items="paces" :fields="fields" v-cloak>
        <template #cell(name)="data">
          <span :class="isNaN(data.item.name)? 'text-muted': 'font-weight-bold zone' + data.item.name">{{ data.item.name }}</span>
        </template>
        <template #cell(range)="data">
          <span :class="isNaN(data.item.name)? 'text-muted ': 'font-weight-bold zone' + data.item.name">
              <Duration :duration="data.item.hi" :showIcon="false"/>
          -
              <Duration :duration="data.item.lo" :showIcon="false"/>
            </span>
        </template>
      </b-table>
    </b-card-body>
  </b-card>
</template>

<script>

import Duration from "@/components/Duration";

export default {

  name: 'Endurance8020',
  components: {
    Duration
  },
  props: {
    username: String,
  },
  data() {
    return {
      thresholdPace: Number,
      selectorValue: '',
      paces: [],
      fields: [
        {key: 'name', label: 'Zone'},
        {key: 'range', label: 'Pace Range'},
      ],
    }
  },
  watch: {
    username: function () {
      this.updateZones();
    }
  },
  mounted() {
    this.updateZones();
  },
  methods: {
    updateZones() {
      if (this.username) {
        this.$http.get(`/api/profile`)
            .then(response => {
              this.thresholdPace = response.data.data.thresholdPace
              let min = Math.floor(this.thresholdPace / 60);
              let sec = this.thresholdPace % 60;
              this.selectorValue = `${min}:${sec}`
              this.paces = response.data.data.endurance8020Zones;
            })
            .catch(e => {
              console.log(e)
            })
      }
    },
    onPaceUpdate(selectorValue) {
      let tokens = selectorValue.split(':')
      let min = parseInt(tokens[0]);
      let sec = parseInt(tokens[1]);

      this.$http.post(`/api/update_user_data`, {
        thresholdPace: {minutes: min, seconds: sec}
      })
          .then(() => {
            this.updateZones();
          })
          .catch(e => {
            console.log(e)
          })
    }
  },
}
</script>

<style scoped>

</style>
