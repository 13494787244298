<template>
  <b-container>
    <b-alert show variant="danger" v-if="globalData.organizations.length === 0 ">
      You are not assign to any team yet. Use your joining link, or contact your coach to obtain one.
    </b-alert>
    <b-row class="text-left">
      <b-col>
        <h2>{{ globalData.activeUser.name }}&#8205;</h2>
        <h5 class="text-secondary">
          <font-awesome-icon icon="user" size="1x"/>
          {{ globalData.activeUser.username }}
        </h5>
      </b-col>
    </b-row>
    <b-card-group columns class="m-2">
      <GarminConnector :globalData="globalData"/>
      <UserWorkouts/>
      <Endurance8020 :username="globalData.activeUser.username"></Endurance8020>
      <LabelList
          :globalData="globalData"
          :selectedLabelUuids="globalData.activeUser.data ? globalData.activeUser.data.labelUuids : []"
          v-on:selected-labels-changes="onSelectedLabelsChange"
      />
    </b-card-group>
  </b-container>
</template>

<script>

import Endurance8020 from "../components/Endurance8020";
import GarminConnector from "../components/Connectors/GarminConnector";
import LabelList from "../components/Labels/LabelList";
import UserWorkouts from "../components/Workouts/UserWorkouts.vue"

export default {
  name: 'Profile',
  components: {
    Endurance8020,
    GarminConnector,
    LabelList,
    UserWorkouts,
  },
  props: {
    globalData: Object
  },
  computed: {
    username: function () {
      return this.globalData.activeUser.username;
    }
  },
  methods: {
    onSelectedLabelsChange: function (labelUuids) {
      this.$http.post(`/api/update_user_data`, {
        labelUuids: labelUuids
      })
          .then(() => {
          })
          .catch(e => {
            console.error(e)
          })
    },
  }
}
</script>

